<template>
	<div class="container">
		<div class="bg" style="position:absolute;width: 100%;top: 0;bottom: 0;">	
			<img src="../assets/img/3.jpg" style="width:100%;height:100%;"/>
			<img src="../assets/img/2.jpg" style="width:100%;height:100%;"/>
			<img src="../assets/img/1.jpg" style="width:100%;height:100%;"/>
		</div>
		<div style="position: relative;">
		<div class="title">
			<span>电子发票查询</span>
		</div>
		<div class="search-layout">
			<div>
				<a-auto-complete placeholder="请输入企业名称或税号(完整)"
					v-model="query.searchValue"
					:data-source="dataSource"
					:filter-option="filterOption"
					style="width:300px;"
					allowClear="true"
				/>
			</div>
			<div style="margin-top: 20px;">
				<a-range-picker
					:ranges="dateRange"	
					:defaultValue="[moment().subtract(2, 'days'), moment()]"
					:placeholder="['开票开始日期', '开票结束日期']"
					@change="handleDatePicker" 
					style="width: 300px;"
				/>
			</div>
			<div style="margin-top: 20px;">
				<a-button type="primary" @click="search">
					查询发票
				</a-button>
			</div>
		</div>
		<div class="content"
			v-for="(item, index) in dataList"
			:key="index"
		>
			<a-card :title="item.buyerName" style="width: 500px;margin: 0 auto;">
				<div style="text-align: left; font-size: 18px;">
					<div><span>开票日期: {{formateDate(item.invoiceDate)}}</span></div>
					<div><span>发票代码: {{item.invoiceCode}}</span></div>
					<div><span>发票号码: {{item.invoiceNumber}}</span></div>
					<div v-if="item.totalAmount != null"><span>价税合计: {{item.totalAmount + '元'}}</span></div>
					<div style="text-align: center;margin-top: 20px;">
						<a-button type="primary" @click="download(item.url)">
							下载发票
						</a-button>
						<a-button type="primary" @click="handleInvoicePreview(item)" style="margin-left: 20px;">
							发票预览
						</a-button>
					</div>
				</div>
			</a-card>

		</div>
		</div>

		<a-modal v-model="previewVisible" width="60%" title="电子发票预览" @ok="previewVisible = false">
			<img :src="previewImageURL" style="width: 100%;height: 65%;"/>
		</a-modal>
	</div>
</template>

<script>
import { message } from 'ant-design-vue';
import {getElecInvoiceList} from '@/api/elecinvoice';
import moment from 'moment';
import { CookieStorage } from 'cookie-storage';

const cookieStorage = new CookieStorage();

export default {
	data() {
		return{
			query: {
				searchValue: '',
				beginTime: '',
				endTime: ''
			},
			dateRange: {
			},
			dataList: [],
			dataSource:[],
			previewVisible:false,
			previewImageURL: '',
			prefixUrl: process.env.VUE_APP_INVOICE_PREFIX_URL
		}
	},
	created() {
		let start = new Date();
		let end = new Date();
		start.setDate(start.getDate() - 2);
		let startMonth = (start.getMonth() + 1) < 10 ? '0' + (start.getMonth() + 1) : start.getMonth() + 1;
		let startDate = start.getDate() < 10 ? '0' + start.getDate() : start.getDate();
		let endMonth = (end.getMonth() + 1) < 10 ? '0' + (end.getMonth() + 1) : end.getMonth() + 1;
		let endDate = end.getDate() < 10 ? '0' + end.getDate() : end.getDate();
		this.query.beginTime = start.getFullYear() + '-' + startMonth + '-' + startDate + ' 00:00:00'
		this.query.endTime = end.getFullYear() + '-' + endMonth + '-' + endDate +' 23:59:59'

		this.dateRange = {
			'近三天': [moment().subtract(2, 'days'), moment()],
			'近七天': [moment().subtract(6, 'days'), moment()],
			'上一个月': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
			'本月': [moment().startOf('month'), moment().endOf('month')],
		}
		// cookieStorage.setItem('companys', '');
		let history = cookieStorage.getItem('searchValue_2');
		if (history != null && history != '') {
			let strs = history.split(',');
			if (strs.length > 0) {
				for (let str of strs) {
					this.dataSource.push(str)
				}
			}
		}
	},
	methods: {
		moment,
		search() {
			getElecInvoiceList(this.query).then(res => {
				if (res.code === 200) {
					this.dataList = res.rows;

					if (!this.dataList || this.dataList.length === 0) {
						message.info("没有查询到相关电子发票")
					}

					this.addSearchValue();
				} else {
					message.error('请求失败: ' + res.msg)
				}
			})
		},
		handleDatePicker(date, dateString) {
			if (dateString.length == 2) {
				this.query.beginTime = dateString[0] + ' 00:00:00';
				this.query.endTime = dateString[1] + ' 23:59:59';
			}
		},
		download(fileName) {
			// window.location.href = process.env.VUE_APP_BASE_API + "/common/download?fileName=" + encodeURI(fileName + '.pdf');
			window.location.href = this.prefixUrl + fileName;
		},
		filterOption(input, option) {
			if (input == '' || option.componentOptions.children == null || option.componentOptions.children.length == 0) return;
			return (
				option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
			);
    },
		formateDate(dateStr) {
			let date = new Date(Date.parse(dateStr));
			return date.getFullYear() + '年' + (date.getMonth() +1) + '月' + date.getDate() + '日'
		},
		addSearchValue() {
			if (this.query.searchValue == null || this.query.searchValue == '') return;
			let idx = this.dataSource.indexOf(this.query.searchValue)
			if (idx >= 0) {
				this.dataSource.splice(idx, 1);
			}
			this.dataSource.splice(0, 0,this.query.searchValue);
			if (this.dataSource.length > 5) {
				this.dataSource.splice(5, 1)
			}

			cookieStorage.setItem('searchValue_2', this.dataSource);
		},
    getImageURL(fileName) {
      return this.prefixUrl  + fileName
    },
    handleInvoicePreview(item) {
      if (item.images && item.images.length > 0) {
        this.previewVisible = true
		// this.previewImageUrl = []
        // for (let img of images) {
        //   this.previewImageUrl.push(this.getImagePath(img))
        // }
        this.previewImageURL = this.getImageURL(item.images[0])
      }
    }
	}
}
</script>

<style lang="scss" scoped>
.container {
	position: absolute;
	background-image: url('../assets/img/1.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	min-height: 100%;
	height:auto!important;

	// height: 100%;
}

.title {
	margin-top: 60px;
	margin-bottom: 20px;
	text-align: center;
	font-size: 38px;
	font-weight: bold;
	padding: 12px 0px;
	color: white
}

.search-layout {
	text-align: center;
	
}

.content {
	text-align: center;
	margin-top: 30px;
}



.bg img {
  position:absolute;
  left:0;
}

@-webkit-keyframes imgFade {
 0% {
   opacity:1;
 }
 25% {
   opacity:0;
 }
 50% {
   opacity:0;
 }
//  92% {
//    opacity:0;
//  }
 75% {
   opacity:1;
 }
}

@-moz-keyframes imgFade {
 0% {
   opacity:1;
 }
 25% {
   opacity:0;
 }
 50% {
   opacity:0;
 }
//  92% {
//    opacity:0;
//  }
 75% {
   opacity:1;
 }
}

@-o-keyframes imgFade {
 0% {
   opacity:1;
 }
 25% {
   opacity:0;
 }
 50% {
   opacity:0;
 }
//  92% {
//    opacity:0;
//  }
 75% {
   opacity:1;
 }
}

@keyframes imgFade {
 0% {
   opacity:1;
 }
 25% {
   opacity:0;
 }
 50% {
   opacity:0;
 }
//  92% {
//    opacity:0;
//  }
 75% {
   opacity:1;
 }
}

.bg img {
  -webkit-animation-name: imgFade;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 40s;

  -moz-animation-name: imgFade;
  -moz-animation-timing-function: ease-in-out;
  -moz-animation-iteration-count: infinite;
  -moz-animation-duration: 40s;

  -o-animation-name: imgFade;
  -o-animation-timing-function: ease-in-out;
  -o-animation-iteration-count: infinite;
  -o-animation-duration: 40s;

  animation-name: imgFade;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 40s;
}

.bg img:nth-of-type(1) {
  -webkit-animation-delay: 30s;
  -moz-animation-delay: 30s;
  -o-animation-delay: 30s;
  animation-delay: 30s;
}
.bg img:nth-of-type(2) {
  -webkit-animation-delay: 20s;
  -moz-animation-delay: 20s;
  -o-animation-delay: 20s;
  animation-delay: 20s;
}
.bg img:nth-of-type(3) {
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}
</style>