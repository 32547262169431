<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <Index/> 
    </a-config-provider>
  </div>
</template>

<script>
import Index from '@/views/Index'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';

export default {
  name: 'App',
  components: {
    Index
  },
  data() {
    return {
      locale: zhCN,
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
