import axios from 'axios'

const service = new axios.create({
	baseURL: process.env.VUE_APP_BASE_API
});

service.interceptors.response.use(res => {
	return res.data
})

export default service;

